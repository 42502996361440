import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCzsPDfD-3fRiEDBxeBrTksDIEYcjzym3s",
  authDomain: "sitprep-new.firebaseapp.com",
  projectId: "sitprep-new",
  storageBucket: "sitprep-new.appspot.com",
  messagingSenderId: "130537267759",
  appId: "1:130537267759:web:7e82175660b71df4c51cda",
  measurementId: "G-RR28NE2L0F"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const messaging = getMessaging(app);
const auth = getAuth(app);

const uploadFileToStorage = async (file, path) => {
  const storageRef = ref(storage, path);
  try {
    const snapshot = await uploadBytes(storageRef, file);
    return snapshot;
  } catch (error) {
    console.error('Error uploading file to storage:', error);
    throw error;
  }
};

const requestForToken = async () => {
  try {
    const token = await getToken(messaging, { vapidKey: "BAqHNMfHOt-A5X8q3u_uJV7Sm9-n9hkPK5YB4D-H4K7RvJqZuEXgm5q-GiTto6a1V2WYk9cAyilugfkJeuRvqQc" });
    if (token) {
      console.log('FCM token:', token);
      return token;
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (error) {
    console.error('An error occurred while retrieving token.', error);
  }
};

const onMessageListener = () => new Promise((resolve) => onMessage(messaging, resolve));

export { firebaseConfig, db, storage, getDownloadURL, ref, deleteObject, auth, messaging, uploadFileToStorage, requestForToken, onMessageListener };
