import axios from 'axios';

const API_BASE_URL = 'https://statusgo-db-0889387bb209.herokuapp.com/api';

export const fetchAllUserInfo = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/userinfo`);
    return response.data;
  } catch (error) {
    console.error('Error fetching all user info', error);
    throw error;
  }
};

export const fetchUserProfileByEmail = async (email) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/userinfo/email/${email}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile by email', error);
    throw error;
  }
};

export const fetchAllGroups = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/groups`);
    return response.data;
  } catch (error) {
    console.error('Error fetching all groups', error);
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/userinfo`, userData);
    return response.data;
  } catch (error) {
    console.error('Error creating user', error);
    throw error;
  }
};

export const createGroup = async (groupData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/groups`, groupData);
    return response.data;
  } catch (error) {
    console.error('Error creating group', error);
    throw error;
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${API_BASE_URL}/userinfo/${userId}`,
      data: userData,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

export const updateGroup = async (groupId, groupData) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${API_BASE_URL}/groups/${groupId}`,
      data: groupData,
      headers: {
        'Content-Type': 'application/json',
      
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating group', error);
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/userinfo/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting user', error);
    throw error;
  }
};

export const deleteGroup = async (groupId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/groups/${groupId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting group', error);
    throw error;
  }
};

export const fetchUserByEmail = async (email) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/userinfo/email/${email}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null; // User not found
    } else {
      console.error('Error fetching user by email', error);
      throw error;
    }
  }
};

export const updateUserStatus = async (email, statusData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/userinfo/email/${email}`, statusData);
    return response.data;
  } catch (error) {
    console.error('Error updating user status', error);
    throw error;
  }
};

export const fetchGroupById = async (groupId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/groups/${groupId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching group by ID', error);
    throw error;
  }
};

// Methods for posts
export const createPost = async (postData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/posts`, postData);
    return response.data;
  } catch (error) {
    console.error('Error creating post', error);
    throw error;
  }
};

export const fetchPostsByGroupId = async (groupId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/posts/group/${groupId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts by group ID', error);
    throw error;
  }
};


export const updatePost = async (postId, postData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/posts/${postId}`, postData);
    return response.data;
  } catch (error) {
    console.error('Error updating post', error);
    throw error;
  }
};

export const deletePost = async (postId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/posts/${postId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting post:', error);
    throw error;
  }
};

// Methods for comments
export const createComment = async (commentData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/comments`, commentData);
    return response.data;
  } catch (error) {
    console.error('Error creating comment', error);
    throw error;
  }
};

export const fetchCommentsByPostId = async (postId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/comments/${postId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching comments by post ID', error);
    throw error;
  }
};

export const updateComment = async (commentId, commentData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/comments/${commentId}`, commentData);
    return response.data;
  } catch (error) {
    console.error('Error updating comment', error);
    throw error;
  }
};

export const deleteComment = async (commentId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/comments/${commentId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting comment', error);
    throw error;
  }
};
