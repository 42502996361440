import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import GreetingMessage from './GreetingMessage';
import '../App.css';
import ShareApp from './shareApp';

const UserInfo = () => {
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [userFirstName, setUserFirstName] = useState('');
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserProfile = () => {
      if (currentUser) {
        try {
          const userProfileData = JSON.parse(localStorage.getItem(`userData-${currentUser.email}`));
          if (userProfileData) {
            setUserProfile(userProfileData);
            setUserFirstName(userProfileData.userFirstName);
            setUserData(userProfileData); // Store user profile data
          }
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      }
    };

    fetchUserProfile();
  }, [currentUser]);

  const profile = async () => {
    navigate('/Profile');
  };

  const toggleOffcanvas = () => {
    setOffcanvasOpen(!offcanvasOpen);
  };

  const handleSignOut = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <>
      {currentUser && (
        <div className='d-flex  pt-3'>
          <GreetingMessage userFirstName={userFirstName} currentUserEmail={currentUser.email} />
          <ShareApp />
          
        </div>
        
      )}
      {/* Conditional messages for missing phone number and address */}
      <div className="py-1 justify-content-center  mb-4">
            {!userData?.phone && (
              <div className="mx-2 shadow-sm btn rounded-pill flex items-center text-dark mb-2 bg-white">
                <FaPhone className="mr-2" />
                <Link to="/profile" className="text-sm text-dark ">
                  Add a phone number to your profile
                </Link>
              </div>
            )}
            {!userData?.address && (
              <div className="mx-2 shadow-sm btn bg-white rounded-pill flex items-center text-dark mb-2">
                <FaMapMarkerAlt className="mr-2" />
                <Link to="/profile" className="text-sm text-dark ">
                  Add an address to your profile
                </Link>
              </div>
            )}
          </div>
    </>
  );
};

export default UserInfo;
