// src/index.js

import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { AppContextProvider } from './AppContext';

import reportWebVitals from './reportWebVitals';
import { registerServiceWorker } from './serviceWorkerRegistration';



function Index() {
  const pullToRefreshRef = useRef(null);
  const startYRef = useRef(0);

  // Function to play a sound
  const playSound = () => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = audioContext.createOscillator();
    const gainNode = audioContext.createGain();

    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(440, audioContext.currentTime); // Set frequency to 440 Hz (A4 note)
    oscillator.connect(gainNode);
    gainNode.connect(audioContext.destination);

    oscillator.start();
    gainNode.gain.exponentialRampToValueAtTime(0.0001, audioContext.currentTime + 0.5); // Gradually decrease volume
    oscillator.stop(audioContext.currentTime + 0.5); // Stop after 0.5 seconds
  };

  useEffect(() => {
    const handleTouchStart = (e) => {
      startYRef.current = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
      const currentY = e.touches[0].clientY;
      const pullDistance = currentY - startYRef.current;

      if (pullDistance > 0) {
        pullToRefreshRef.current.style.transform = `translateY(${pullDistance}px)`;
      }
    };

    const handleTouchEnd = async (e) => {
      const pullDistance = e.changedTouches[0].clientY - startYRef.current;

      if (pullDistance > 300) { // Adjust this value as needed
        pullToRefreshRef.current.classList.add('active');

        // Trigger vibration
        if (navigator.vibrate) {
          navigator.vibrate(200); // Vibrate for 200ms
        }

        // Play sound
        playSound();

        // Simulate a network request or any other refresh action
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Refresh the page content or state
        window.location.reload(); // This will refresh the current page and stay on the same URL

        pullToRefreshRef.current.classList.remove('active');
        pullToRefreshRef.current.style.transform = 'translateY(0px)';
      } else {
        pullToRefreshRef.current.style.transform = 'translateY(0px)';
      }
    };

    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  return (
    <React.StrictMode>
      <AppContextProvider>
        <div className="" ref={pullToRefreshRef}>
       
        </div>
        <App />
      </AppContextProvider>
    </React.StrictMode>
  );
}

ReactDOM.render(<Index />, document.getElementById('root'));

reportWebVitals();

registerServiceWorker();
