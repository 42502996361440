import React, { useState, useEffect, useRef } from 'react';
import { createPost, fetchPostsByGroupId, updatePost, deletePost, fetchUserProfileByEmail, fetchGroupById } from '../Api/apiService';
import Comments from './PostComments';
import { FaEllipsisV, FaImage, FaRegSmile } from 'react-icons/fa';
import { Listbox } from '@headlessui/react';
import { CheckCircleIcon, FaceSmileIcon, HeartIcon, PaperClipIcon, ChatBubbleLeftEllipsisIcon } from '@heroicons/react/20/solid';

const moods = [
  { name: 'Excited', value: 'excited', icon: FaceSmileIcon, iconColor: 'text-white', bgColor: 'bg-red-500' },
  { name: 'Loved', value: 'loved', icon: HeartIcon, iconColor: 'text-white', bgColor: 'bg-pink-400' },
  { name: 'Happy', value: 'happy', icon: FaceSmileIcon, iconColor: 'text-white', bgColor: 'bg-green-400' },
  { name: 'I feel nothing', value: null, icon: FaRegSmile, iconColor: 'text-gray-400', bgColor: 'bg-transparent' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Posts = ({ groupId, currentUserEmail }) => {
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState('');
  const [editPostId, setEditPostId] = useState(null);
  const [editPostContent, setEditPostContent] = useState('');
  const [showOptions, setShowOptions] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const optionsRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedGIF, setSelectedGIF] = useState(null);
  const [authorProfiles, setAuthorProfiles] = useState({});
  const [groupName, setGroupName] = useState('');
  const [selectedMood, setSelectedMood] = useState(moods[3]);

  const userData = JSON.parse(localStorage.getItem(`userData-${currentUserEmail}`));
  const userProfileImage = userData?.profileImageURL;
  const userInitials = userData ? `${userData.userFirstName.charAt(0)}${userData.userLastName.charAt(0)}` : '';

  useEffect(() => {
    const fetchGroupAndPosts = async () => {
      try {
        const group = await fetchGroupById(groupId);
        setGroupName(group.groupName);

        const posts = await fetchPostsByGroupId(groupId);
        setPosts(posts);

        const profiles = await Promise.all(posts.map(post => fetchUserProfileByEmail(post.author)));
        const profilesMap = profiles.reduce((acc, profile) => {
          acc[profile.userEmail] = profile;
          return acc;
        }, {});
        setAuthorProfiles(profilesMap);
      } catch (error) {
        console.error('Error fetching group and posts:', error);
      }
    };

    fetchGroupAndPosts();
  }, [groupId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [optionsRef]);

  const handleCreateOrUpdatePost = async () => {
    if (newPost.trim() === '' || newPost.length > 300) return;

    const postData = {
      groupId,
      groupName,
      author: currentUserEmail,
      content: newPost,
      timestamp: new Date().toISOString(),
      image: selectedImage,
      gif: selectedGIF,
      mood: selectedMood.value,
    };

    try {
      if (isEditing) {
        await updatePost(editPostId, postData);
        setPosts(posts.map(post => (post.id === editPostId ? { ...post, ...postData } : post)));
      } else {
        const createdPost = await createPost(postData);
        setPosts([createdPost, ...posts]);
      }
      setNewPost('');
      setSelectedImage(null);
      setSelectedGIF(null);
      setShowModal(false);
    } catch (error) {
      console.error('Error creating or updating post:', error);
    }
  };

  const handleEditPost = (post) => {
    setIsEditing(true);
    setEditPostId(post.id);
    setNewPost(post.content);
    setEditPostContent(post.content);  // Set the initial content for editing
    setShowModal(true);
  };

  const handleDeletePost = async (postId) => {
    try {
      await deletePost(postId);
      setPosts(posts.filter(post => post.id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handleImageUpload = (e) => {
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleGIFUpload = (e) => {
    setSelectedGIF(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <div className="flow-root space-y-6">
      <div className="p-4 bg-white rounded-lg shadow-sm">
        <div className="flex items-center space-x-2">
          <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center overflow-hidden">
            {userProfileImage ? (
              <img src={userProfileImage} alt="Profile" className="w-full h-full object-cover" />
            ) : (
              <span className="text-gray-500">{userInitials}</span>
            )}
          </div>
          <button
            className="w-full p-2 border border-gray-300 rounded-full bg-gray-200 text-left text-gray-500"
            onClick={() => {
              setShowModal(true);
              setIsEditing(false);
            }}
          >
            What's on your mind, {userData.userFirstName}?
          </button>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg overflow-hidden shadow-sm p-6 w-full max-w-lg">
            <h2 className="text-2xl font-bold mb-4">{isEditing ? 'Edit Post' : 'Create Post'}</h2>
            <textarea
              className="w-full p-2 border border-gray-300 rounded mb-4 resize-none focus:outline-none"
              placeholder="What's on your mind?"
              value={isEditing ? editPostContent : newPost}
              onChange={(e) => isEditing ? setEditPostContent(e.target.value) : setNewPost(e.target.value)}
              maxLength={300}
              style={{ height: 'auto' }}
            />
            <div className="text-right text-gray-500">{(isEditing ? editPostContent : newPost).length}/300</div>
            <div className="flex space-x-2 mb-4">
              <input type="file" accept="image/*" onChange={handleImageUpload} className="hidden" id="upload-image" />
              <label htmlFor="upload-image" className="cursor-pointer">
                <FaImage className="text-gray-500 hover:text-gray-700" />
              </label>
              <input type="file" accept="image/gif" onChange={handleGIFUpload} className="hidden" id="upload-gif" />
              <label htmlFor="upload-gif" className="cursor-pointer">
                <FaRegSmile className="text-gray-500 hover:text-gray-700" />
              </label>
            </div>
            <div className="flex items-center space-x-2 mb-4">
              <Listbox value={selectedMood} onChange={setSelectedMood}>
                <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  <span className="block truncate">{selectedMood.name}</span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <FaRegSmile className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-sm-sm max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {moods.map((mood) => (
                    <Listbox.Option
                      key={mood.value}
                      className={({ active }) =>
                        classNames(active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9')
                      }
                      value={mood}
                    >
                      <div className="flex items-center">
                        <span className={classNames(mood.bgColor, 'flex-shrink-0 h-6 w-6 rounded-full flex items-center justify-center')}>
                          <mood.icon className="h-5 w-5 text-white" aria-hidden="true" />
                        </span>
                        <span className="ml-3 block truncate">{mood.name}</span>
                      </div>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Listbox>
            </div>
            <div className="flex space-x-2">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                onClick={handleCreateOrUpdatePost}
              >
                {isEditing ? 'Save' : 'Post'}
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {posts.map((post) => {
        const authorProfile = authorProfiles[post.author];
        const authorProfileImage = authorProfile?.profileImageURL;
        const authorInitials = authorProfile ? `${authorProfile.userFirstName.charAt(0)}${authorProfile.userLastName.charAt(0)}` : '';
        const authorName = authorProfile ? `${authorProfile.userFirstName} ${authorProfile.userLastName}` : post.author;

        return (
          <div key={post.id} className="relative pb-8 bg-white rounded shadow-sm">
            <div className="px-3 pt-4 rounded-lg shadow-sm-md flex ">
              <div className=''>
                <div className="flex relative ">
                  <div className="h-10 w-10 rounded-full bg-gray-400 ring-8 ring-white flex items-center justify-center overflow-hidden">
                    {authorProfileImage ? (
                      <img
                        className="h-full w-full object-cover"
                        src={authorProfileImage}
                        alt={`${authorName}'s profile`}
                      />
                    ) : (
                      <span className="text-white font-semibold">{authorInitials}</span>
                    )}
                  </div>
                  <div className="ps-2 text-sm">
                    <a href="#" className="font-medium text-gray-900">
                      {authorName}
                    </a>
                    <p className="mt-0.5 text-xs text-gray-500">{new Date(post.timestamp).toLocaleString(undefined, { dateStyle: 'medium', timeStyle: 'short', hour12: true })}</p>
                  </div>
                 
                </div>
                <p className='mt-3 ps-2 text-sm'>{post.content}</p>
              </div>
              <div className="ml-auto mt-2 text-sm text-gray-700">
                <button
                  onClick={() => setShowOptions(post.id === showOptions ? null : post.id)}
                  className="text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                  <FaEllipsisV />
                </button>
              </div>
              {post.author === currentUserEmail && (
                <div className=" relative">
                 
                  {post.id === showOptions && (
                    <div ref={optionsRef} className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-sm-sm z-10">
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => {
                          setEditPostId(post.id);
                          setEditPostContent(post.content);
                          setShowOptions(null);
                          setShowModal(true);
                          setIsEditing(true);
                        }}
                      >
                        Edit
                      </button>
                      <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleDeletePost(post.id)}>
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className='mt-4 border-t py-4 px-4'>
              <Comments postId={post.id} currentUserEmail={currentUserEmail} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Posts;
