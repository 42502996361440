import React from 'react';
import Navbar from '../../components-sitprep/Navbar';
import Dashboard from '../../components-sitprep/Dashboard';

const SitPrepHome = () => {
  return (
    <div>
      <Navbar />
      <Dashboard />
    </div>
  );
};

export default SitPrepHome;
