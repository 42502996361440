import React, { useState } from 'react';
import { FaShare } from "react-icons/fa";

const ShareApp = ({ groupId, buttonTextColor = 'text-dark' }) => {
  const [modalOpen, setModalOpen] = useState(false);

  // Function to generate the deep link URL
  const generateDeepLink = (groupId) => {
    // Construct the deep link URL with the group ID parameter using the custom URL scheme
    return `https://www.statusnow.app/joingroup?groupId=${groupId}`;
  };

  // Function to handle copying the link
  const copyLink = () => {
    const deepLink = generateDeepLink(groupId);

    // Copy the deep link to the clipboard
    navigator.clipboard.writeText(deepLink)
      .then(() => {
        alert('Link copied to clipboard!');
      })
      .catch((error) => {
        console.error('Failed to copy link: ', error);
      });
  };

  // Function to handle sending the email
  const sendEmail = () => {
    const deepLink = generateDeepLink(groupId);
    const { subject, body } = composeEmail(deepLink);

    // Create the mailto link
    const emailUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the default email client with the pre-filled subject and body
    window.location.href = emailUrl;
  };

  // Function to handle sharing via text message/SMS
  const shareViaSMS = () => {
    const deepLink = generateDeepLink(groupId);
    const shareData = {
      title: 'Join Our Group',
      text: `Join our group on Status Now by clicking the following link: ${deepLink}`
    };

    // Check if navigator.share is supported
    if (navigator.share) {
      navigator.share(shareData)
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      alert('Sharing via SMS is not supported on this device.');
    }
  };

  // Function to compose the email
  const composeEmail = (deepLink) => {
    // Create the email content with the deep link URL
    const subject = 'Join Our Group';
    const body = `Click the link below to join our group:\n\n${deepLink}`;
    return { subject, body };
  };

  return (
    <div className="flex justify-end ">
      <button className={`text-3xl ${buttonTextColor} font-bold py-2 px-4 rounded`} onClick={() => setModalOpen(true)}>
        <FaShare />
      </button>

      {modalOpen && (
        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 bg-black opacity-75"></div>
            <div className="fixed bg-white rounded-lg p-8 max-w-sm mx-auto">
              <h2 className="text-lg font-bold mb-4">Share Options</h2>
              <div className="flex flex-col space-y-4">
                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded" onClick={sendEmail}>
                  Email
                </button>
                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded" onClick={shareViaSMS}>
                  Text
                </button>
                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded" onClick={copyLink}>
                  Copy Link
                </button>
                <button className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded" onClick={() => setModalOpen(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareApp;
