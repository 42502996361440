import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import LeaveGroup from '../components/LeaveGroup';
import { fetchGroupById, fetchAllUserInfo } from '../Api/apiService';
import Posts from '../components/Post';
import { FaBell, FaEdit, FaHome, FaSignOutAlt, FaPhone, FaMapMarkerAlt } from 'react-icons/fa'; // Import relevant icons
import Members from '../components/Members';

const MemberGroup = () => {
  const { groupId } = useParams();
  const currentUserEmail = localStorage.getItem('currentUserEmail');
  const [groupData, setGroupData] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const loadGroupData = async () => {
      try {
        const group = await fetchGroupById(groupId);
        setGroupData(group);
      } catch (error) {
        console.error('Error fetching group data:', error);
      }
    };

    const loadUserData = async () => {
      try {
        const allUserInfo = await fetchAllUserInfo();
        const user = allUserInfo.find(user => user.userEmail === currentUserEmail);
        setUserData(user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    loadGroupData();
    loadUserData();
  }, [groupId, currentUserEmail]);

  if (!groupData || !userData) {
    return <div className="text-center mt-10">Loading...</div>;
  }

  const isAdmin = groupData.adminEmails.includes(currentUserEmail);

  return (
    <main className="lg:container mx-auto">
      <div className="navbarCst px-4 pb-4 pt-5 shadow lg:rounded-t-lg">
        <div>
          <div className="mt-5 flex justify-between">
            <Link className="shadow btn btn-light mr-2 rounded-full p-3" to="/">
              <FaHome className="text-xl text-dark" />
            </Link>
            <LeaveGroup groupId={groupId} />
          </div>
        </div>
      </div>
      <div className="pt-3 px-4 bg-white shadow-sm flex justify-content-between">
        <div>
          <h1 className="text-xl font-bold text-dark">{groupData.groupName}</h1>
          <p className="text-dark text-sm">{groupData.description}</p>
        </div>
        <div>
          {isAdmin && (
            <Link
              to={`/groups/${groupId}/edit`}
              className="text-white hover:text-blue-700 flex justify-content-end"
            >
              <FaEdit className="text-2xl inline-block mr-1 text-dark" />
            </Link>
          )}
        </div>
      </div>

      <div className="">
       
          <div className=" bg-white pb-3 px-4 d-flex justify-content-between border-bottom ">
          {groupData.alert === 'Active' && (
            <div className="mt-2 flex items-center">
              <p className="text-secondary font-bold">
                Member Status Update: <span className="font-normal">{groupData.alert}</span>
              </p>
            </div>
          )}
            {isAdmin && (
              <div className=" justify-content-center d-flex  mt-2">
                {groupData.alert === 'Inactive' ? (
                  <Link
                    to={`/Group/${groupId}`}
                    className="text-1xl rounded p-2 bg-red-500 text-white hover:text-blue-700 "
                  >
                    Get Status
                  </Link>
                ) : (
                  <Link
                    to={`/Group/${groupId}`}
                    className="text-1xl rounded p-2 bg-green-700 text-white hover:text-blue-700"
                  >
                    View Report
                  </Link>
                )}
              </div>
            )}
       

          </div>
          
       
        {isAdmin && <Members memberEmails={groupData.memberEmails} memberCount={groupData.memberCount} />}
      </div>

    
      <div className="">
        <Posts groupId={groupId} currentUserEmail={currentUserEmail} />
      </div>
    </main>
  );
};

export default MemberGroup;
