import React, { useEffect, useState } from 'react';
import { IoMdMore } from "react-icons/io";
import { Link } from 'react-router-dom';
import { fetchAllGroups, fetchAllUserInfo } from '../Api/apiService';

const SubGroup = ({ groupId }) => {
  const [group, setGroup] = useState(null);
  const [ownerProfile, setOwnerProfile] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all groups and user info
        const allGroups = await fetchAllGroups();
        const allUserInfo = await fetchAllUserInfo();

        // Find the group by groupId
        const foundGroup = allGroups.find((group) => group.groupId === groupId);
        setGroup(foundGroup);

        if (foundGroup) {
          // Find the owner's profile using the owner's email
          const foundOwnerProfile = allUserInfo.find((profile) => profile.userEmail === foundGroup.ownerEmail);
          setOwnerProfile(foundOwnerProfile);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [groupId]);

  if (!group || !ownerProfile) {
    return null; // Handle the case when the group or owner profile is not found
  }

  const { groupName } = group;
  const { userFirstName, userLastName } = ownerProfile;

  // Render the subgroup with the name and owner's name
  return (
    <div className="container mt-3">
      <div className="card border">
        <div className="card-body d-flex">
          <div>
            <div>
              <div>
                <div
                  className="rounded-circle border p-1 d-flex align-items-center justify-content-center"
                  style={{
                    width: '60px',
                    height: '60px',
                    backgroundColor: 'gray', // You can customize the background color
                    borderRadius: '50%',
                  }}
                >
                  {/* You can add an image for the subgroup if needed */}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-grow-1 pl-3">
            <div className="font-weight-bold text-dark">{groupName}</div>
            <div className="text-secondary">Owner: {userFirstName} {userLastName}</div>
          </div>
          <div>
            <Link to={`/Group/${groupId}`} className="text-decoration-none text-dark">
              <IoMdMore />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubGroup;
