import React, { useState, useEffect, useRef } from 'react';
import { FaUsers, FaSearch, FaEllipsisV } from 'react-icons/fa';
import Modal from 'react-modal';
import { fetchUserProfileByEmail, updateGroup } from '../Api/apiService';

const Members = ({ memberEmails, currentUserEmail, groupId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [memberProfiles, setMemberProfiles] = useState([]);
  const [showOptions, setShowOptions] = useState(null);
  const optionsRef = useRef(null);
  const buttonRefs = useRef({});

  useEffect(() => {
    const fetchProfiles = async () => {
      const profiles = await Promise.all(memberEmails.map(id => fetchUserProfileByEmail(id)));
      setMemberProfiles(profiles);
    };

    fetchProfiles();
  }, [memberEmails]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [optionsRef]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleSearch = (event) => setSearchTerm(event.target.value);

  const handleAddAsAdmin = async (email) => {
    try {
      const updatedGroup = {
        adminEmails: [...new Set([...memberProfiles.find(profile => profile.userEmail === email).adminEmails, email])]
      };
      await updateGroup(groupId, updatedGroup);
      setMemberProfiles(memberProfiles.map(profile =>
        profile.userEmail === email ? { ...profile, isAdmin: true } : profile
      ));
    } catch (error) {
      console.error('Error adding as admin:', error);
    }
  };

  const handleRemoveAsAdmin = async (email) => {
    try {
      const updatedGroup = {
        adminEmails: memberProfiles.find(profile => profile.userEmail === email).adminEmails.filter(adminEmail => adminEmail !== email)
      };
      await updateGroup(groupId, updatedGroup);
      setMemberProfiles(memberProfiles.map(profile =>
        profile.userEmail === email ? { ...profile, isAdmin: false } : profile
      ));
    } catch (error) {
      console.error('Error removing as admin:', error);
    }
  };

  const handleMakeOwner = async (email) => {
    try {
      const updatedGroup = {
        ownerEmail: email,
        ownerName: `${memberProfiles.find(profile => profile.userFirstName && profile.userLastName)}`
      };
      await updateGroup(groupId, updatedGroup);
      setMemberProfiles(memberProfiles.map(profile =>
        profile.userEmail === email ? { ...profile, isOwner: true } : profile
      ));
    } catch (error) {
      console.error('Error making owner:', error);
    }
  };

  const handleRemoveMember = async (email) => {
    try {
      const updatedGroup = {
        memberEmails: memberEmails.filter(memberEmail => memberEmail !== email),
        adminEmails: memberProfiles.find(profile => profile.userEmail === email).isAdmin ? memberEmails.filter(adminEmail => adminEmail !== email) : memberEmails
      };
      await updateGroup(groupId, updatedGroup);
      setMemberProfiles(memberProfiles.filter(profile => profile.userEmail !== email));
    } catch (error) {
      console.error('Error removing member:', error);
    }
  };

  const filteredMembers = memberProfiles.filter(profile =>
    profile.userEmail.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getPositionStyle = (email) => {
    if (buttonRefs.current[email]) {
      const rect = buttonRefs.current[email].getBoundingClientRect();
      return {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX - 180, // Adjust this value to move the dropdown more to the left
      };
    }
    return {};
  };

  return (
    <div className="lg:rounded-b-lg  border-black-500 p-2 px-4 bg-white border-bottom">
      <div className="flex items-center justify-between ">
        <div className="flex items-center">
          <FaUsers className="text-secondary mr-2" />
          <p className="text-secondary text-lg font-semibold">{filteredMembers.length}</p>
          <ul className="ms-3 space-y-4">
            <div className="flex -space-x-1 overflow-hidden p-2">
              {filteredMembers.slice(0, 5).map((profile, index) => {
                const authorInitials = profile.userFirstName && profile.userLastName ? `${profile.userFirstName.charAt(0)}${profile.userLastName.charAt(0)}` : '';
                return (
                  <div key={index} className="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow-sm border border-white flex items-center justify-center overflow-hidden bg-gray-400 text-white font-semibold">
                    {profile.profileImageURL ? (
                      <img
                        src={profile.profileImageURL}
                        alt={profile.userEmail}
                        className="h-full w-full object-cover"
                      />
                    ) : (
                      <span>{authorInitials}</span>
                    )}
                  </div>
                );
              })}
            </div>
          </ul>
        </div>
        <button
          className="font-bold text-blue-500 hover:text-blue-700"
          onClick={handleOpenModal}
        >
          Manage
        </button>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Manage Members"
        className="bg-white rounded shadow p-6 w-full max-w-3xl mx-auto"
        overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center"
      >
        <h2 className="text-2xl font-bold mb-4">Members</h2>
        <div className="mb-4 flex items-center">
          <FaSearch className="text-gray-500 mr-2" />
          <input
            type="text"
            placeholder="Search members..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <ul className="space-y-4 max-h-96 overflow-y-auto my-5">
          {filteredMembers.map((profile, index) => {
            const authorInitials = profile.userFirstName && profile.userLastName ? `${profile.userFirstName.charAt(0)}${profile.userLastName.charAt(0)}` : '';
            return (
              <li key={index} className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="w-10 h-10 rounded-full mr-3 flex items-center justify-center overflow-hidden bg-gray-400 text-white font-semibold">
                    {profile.profileImageURL ? (
                      <img
                        src={profile.profileImageURL}
                        alt={profile.userEmail}
                        className="h-full w-full object-cover"
                      />
                    ) : (
                      <span>{authorInitials}</span>
                    )}
                  </div>
                  <div>
                    <p className="text-gray-700 font-semibold">{profile.userFirstName} {profile.userLastName}</p>
                    <p className="text-gray-500 text-sm">{profile.userEmail}</p>
                  </div>
                </div>
                <div className="relative">
                  {profile.userEmail !== currentUserEmail && (
                    <button
                      ref={(el) => (buttonRefs.current[profile.userEmail] = el)}
                      onClick={() => setShowOptions(profile.userEmail === showOptions ? null : profile.userEmail)}
                      className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    >
                      <FaEllipsisV />
                    </button>
                  )}
                  {profile.userEmail === showOptions && (
                    <div
                      ref={optionsRef}
                      className=" fixed w-48 bg-white border border-gray-200 rounded-md shadow z-10"
                      style={getPositionStyle(profile.userEmail)}
                    >
                      {profile.isAdmin ? (
                        <button
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => handleRemoveAsAdmin(profile.userEmail)}
                        >
                          Remove as Admin
                        </button>
                      ) : (
                        <button
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => handleAddAsAdmin(profile.userEmail)}
                        >
                          Add as Admin
                        </button>
                      )}
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => handleMakeOwner(profile.userEmail)}
                      >
                        Make Owner
                      </button>
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleRemoveMember(profile.userEmail)}>
                        Remove Member
                      </button>
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
        <div className="mt-4 flex justify-end">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            onClick={handleCloseModal}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Members;
