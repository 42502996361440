import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Navigate, Routes, Route, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import { requestForToken, messaging } from './firebase';
import { fetchUserByEmail, updateUser } from './Api/apiService';
import { onMessage } from 'firebase/messaging';

import HomePage from './pages/HomePage';
import MyGroup from './pages/MyGroup';
import GetStatus from './pages/GetStatus';
import NotFoundPage from './pages/NotFoundPage';
import Login from './components/login';
import Register from './pages/createUser';
import LandingPage from './pages/landingPage';
import CreateGroup from './pages/creatingGroups';
import ChangeStatusPage from './pages/StatusChangePage.js';
import Profile from './pages/profilePage.js';
import JoinGroup from './pages/JoinGroupPage.js';
import MemberGroup from './pages/memberGroup.js';
import ManageGroup from './components/Group Comps/ManageGroup.js';
import NotificationsPage from './pages/NotificationsPage.js';
import UpdateStatusPage from './pages/StatusUpdate.js';
import EmbeddedSite from './components/sitprep.js';
import SitPrepHome from './pages/sitprep-pages/SitPrepHome.js';


function AppAuth() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [currentUserEmail, setCurrentUserEmail] = useState('');

  useEffect(() => {
    const requestNotificationPermission = async () => {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      } else {
        console.error('Notification permission denied.');
      }
    };

    const handleForegroundMessages = () => {
      onMessage(messaging, (payload) => {
        console.log('Received foreground message: ', payload);
    
        const { title, body, icon, badge, type, groupId } = payload.data;
    
        const notificationTitle = title || 'Default Title';
        const notificationOptions = {
          body: body || 'Default Body',
          icon: icon || '/images/icon-120.png',
          badge: badge || '/images/icon-512.png',
          requireInteraction: true,
          sound: 'default',
          data: { type, groupId } // Add type and groupId to the notification data
        };
    
        const notification = new Notification(notificationTitle, notificationOptions);
    
        notification.onclick = (event) => {
          event.preventDefault(); // Prevent the browser from focusing the Notification's tab
          let url = '/';
    
          // Determine the URL to navigate based on the notification type
          if (type === 'alert') {
            url = '/status-now';
          } else if (type === 'new_member') {
            url = `/Linked/${groupId}`;
          } else if (type === 'post_notification') {
            url = `/Linked/${groupId}`;
          }
    
          window.open(url, '_blank');
        };
      });
    };
    

    const refreshToken = async () => {
      if (currentUser) {
        const lastTokenUpdate = localStorage.getItem('lastTokenUpdate');
        const oneWeek = 7 * 24 * 60 * 60 * 1000;
        const currentTime = Date.now();

        if (!lastTokenUpdate || currentTime - lastTokenUpdate > oneWeek) {
          const currentToken = await requestForToken();
          const userProfile = await fetchUserByEmail(currentUser.email);
          if (userProfile && currentToken) {
            await updateUser(userProfile.id, { ...userProfile, fcmtoken: currentToken });
            localStorage.setItem('lastTokenUpdate', currentTime.toString());
          }
        }
      }
    };

    const initializeUser = () => {
      if (currentUser) {
        const currentUserEmailFromStorage = localStorage.getItem('currentUserEmail');
        if (!currentUserEmailFromStorage || currentUserEmailFromStorage !== currentUser.email) {
          const joingroupId = localStorage.getItem('joingroupId');
          localStorage.clear();
          if (joingroupId) {
            localStorage.setItem('joingroupId', joingroupId);
          }
          localStorage.setItem('currentUserEmail', currentUser.email);
        }
        setCurrentUserEmail(currentUser.email);
      }
    };

    requestNotificationPermission();
    handleForegroundMessages();
    initializeUser();
    refreshToken(); // Refresh token on initial load

    const interval = setInterval(() => {
      refreshToken(); // Refresh token every hour
    }, 3600000); // 3600000ms = 1 hour

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [currentUser]);

  return (
    <div className="App bg-light">
      <section className="font-sans padding-app">
        <Routes>
          <Route
            path="/"
            element={currentUser ? <Navigate to="/home" /> : <Navigate to="/landing" />}
          />
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/notifications" element={<NotificationsPage currentUserEmail={currentUserEmail} />} /> 
          <Route
            path="/home"
            element={currentUser ? <HomePage currentUserEmail={currentUserEmail} /> : <Navigate to="/landing" />}
          />
           <Route
            path="/sitprep"
            element={currentUser ? <SitPrepHome currentUserEmail={currentUserEmail} /> : <Navigate to="/landing" />}
          />




          <Route path="/joingroup" element={<JoinGroup />} />
          <Route
            path="/Linked/:groupId"
            element={currentUser ? <MemberGroup /> : <Navigate to="/landing" />}
          />
          <Route
            path="/Group/:groupId"
            element={currentUser ? <MyGroup /> : <Navigate to="/landing" />}
          />
          <Route
            path="/groups/:groupId/edit"
            element={currentUser ? <ManageGroup /> : <Navigate to="/landing" />}
          />
          <Route
            path="/Status/:groupId"
            element={currentUser ? <GetStatus /> : <Navigate to="/landing" />}
          />
          <Route
            path="/login"
            element={currentUser ? <Navigate to="/home" /> : <Login />}
          />
          <Route
            path="/create-account"
            element={currentUser ? <Navigate to="/home" /> : <Register />}
          />
          <Route
            path="/create-group"
            element={currentUser ? <CreateGroup /> : <Navigate to="/landing" />}
          />
          <Route
            path="/status-now"
            element={currentUser ? <UpdateStatusPage /> : <Navigate to="/landing" />}
          />
          <Route
            path="/change-status"
            element={currentUser ? <ChangeStatusPage /> : <Navigate to="/landing" />}
          />
          <Route path="/embedded-site" element={<EmbeddedSite  />} />
         
     
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </section>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AppAuth />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
