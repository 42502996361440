import CreateGroup from "../components/Group Comps/createGroup";

const CreateMyGroups = () => {
    return (
<div>
    <CreateGroup/>
</div>
    );
}

export default CreateMyGroups