import React, { useState, useEffect, useRef } from 'react';
import { fetchCommentsByPostId, createComment, updateComment, deleteComment, fetchUserProfileByEmail } from '../Api/apiService';
import { FaEllipsisV, FaPaperPlane } from 'react-icons/fa';

const Comments = ({ postId, currentUserEmail }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [editCommentId, setEditCommentId] = useState(null);
  const [editCommentContent, setEditCommentContent] = useState('');
  const [showOptions, setShowOptions] = useState(null);
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [authorProfiles, setAuthorProfiles] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAllComments, setShowAllComments] = useState(false);
  const [sortOption, setSortOption] = useState('recent');
  const optionsRef = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const data = await fetchCommentsByPostId(postId);
        setComments(data);

        const profiles = await Promise.all(data.map(comment => fetchUserProfileByEmail(comment.author)));
        const profilesMap = profiles.reduce((acc, profile) => {
          acc[profile.userEmail] = profile;
          return acc;
        }, {});
        setAuthorProfiles(profilesMap);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    fetchComments();
  }, [postId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(null);
      }
      if (textareaRef.current && !textareaRef.current.contains(event.target)) {
        setIsAddingComment(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [optionsRef, textareaRef]);

  useEffect(() => {
    const savedComment = localStorage.getItem(`comment-${postId}`);
    if (savedComment) {
      setNewComment(savedComment);
    }
  }, [postId]);

  useEffect(() => {
    if (newComment) {
      localStorage.setItem(`comment-${postId}`, newComment);
    }
  }, [newComment, postId]);

  const getLocalISOString = () => {
    const now = new Date();
    const timezoneOffset = now.getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = new Date(now - timezoneOffset).toISOString().slice(0, -1); // remove the 'Z'
    return localISOTime;
  };

  const handleCreateComment = async () => {
    if (newComment.trim() === '') return;

    try {
      const commentData = {
        postId,
        author: currentUserEmail,
        content: newComment,
        timestamp: getLocalISOString(),
      };
      const createdComment = await createComment(commentData);
      const profile = await fetchUserProfileByEmail(currentUserEmail);
      setComments([{ ...createdComment, authorProfile: profile }, ...comments]);
      setAuthorProfiles({ ...authorProfiles, [currentUserEmail]: profile });
      setNewComment('');
      setIsAddingComment(false);
    } catch (error) {
      console.error('Error creating comment:', error);
    }
  };

  const handleEditComment = async () => {
    if (editCommentContent.trim() === '') return;

    try {
      const updatedComment = await updateComment(editCommentId, { content: editCommentContent });
      setComments(comments.map(comment => (comment.id === editCommentId ? updatedComment : comment)));
      setEditCommentId(null);
      setEditCommentContent('');
      setShowEditModal(false);
    } catch (error) {
      console.error('Error editing comment:', error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await deleteComment(commentId);
      setComments(comments.filter(comment => comment.id !== commentId));
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const handleTextareaInput = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
    setNewComment(e.target.value);
  };

  const toggleShowOptions = (id) => {
    setShowOptions(id === showOptions ? null : id);
  };

  const sortedComments = [...comments].sort((a, b) => {
    if (sortOption === 'recent') {
      return new Date(b.timestamp) - new Date(a.timestamp);
    } else if (sortOption === 'oldest') {
      return new Date(a.timestamp) - new Date(b.timestamp);
    }
    return 0;
  });

  const commentsToShow = showAllComments ? sortedComments : sortedComments.slice(0, 2);

  return (
    <div className="">
      <div className="flex justify-end border-gray-400 mb-4">
        <button
          className="inline-flex rounded-pill btn btn-sm btn-outline-dark "
          onClick={() => setSortOption(sortOption === 'recent' ? 'oldest' : 'recent')}
        >
          Sort by {sortOption === 'recent' ? 'Oldest' : 'Recent'}
        </button>
      </div>
      {commentsToShow.map((comment) => {
        const authorProfile = authorProfiles[comment.author];
        const authorProfileImage = authorProfile?.profileImageURL;
        const authorInitials = authorProfile ? `${authorProfile.userFirstName.charAt(0)}${authorProfile.userLastName.charAt(0)}` : '';
        const authorName = authorProfile ? `${authorProfile.userFirstName} ${authorProfile.userLastName}` : comment.author;

        return (
          <div key={comment.id} className="relative pb-3">
            <div className="rounded-lg flex space-x-4 relative items-start">
              <div className="relative flex-shrink-0 h-10 w-10 rounded-full bg-gray-400 ring-4 ring-white flex items-center justify-center overflow-hidden">
                {authorProfileImage ? (
                  <img
                    className="h-full w-full object-cover"
                    src={authorProfileImage}
                    alt={`${authorName}'s profile`}
                  />
                ) : (
                  <span className="text-white font-semibold">{authorInitials}</span>
                )}
              </div>

              <section className=''>
                <div className="flex-1 bg-gray-100 rounded-card inline-flex overflow-auto">
                  <div className="p-2 text-sm whitespace-pre-wrap text-break">
                    <a href="#" className="font-medium text-gray-900">
                      {authorName}
                    </a>
                    <p className='text-wrap md:text-balance'>{comment.content}</p>
                  </div>
                </div>
                <p className="mt-0.5 text-xs text-gray-500 px-2">{new Date(comment.timestamp).toLocaleString(undefined, { dateStyle: 'medium', timeStyle: 'short', hour12: true })}</p>
              </section>

              {comment.author === currentUserEmail && (
                <div className="ml-auto relative">
                  <button
                    onClick={() => toggleShowOptions(comment.id)}
                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    aria-label="Options"
                  >
                    <FaEllipsisV />
                  </button>
                  {comment.id === showOptions && (
                    <div ref={optionsRef} className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-sm z-10">
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => {
                          setEditCommentId(comment.id);
                          setEditCommentContent(comment.content);
                          setShowOptions(null);
                          setShowEditModal(true);
                        }}
                      >
                        Edit
                      </button>
                      <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleDeleteComment(comment.id)}>
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}

      {comments.length > 2 && (
        <div className="text-sm text-gray-500 font-semibold cursor-pointer mb-4" onClick={() => setShowAllComments(!showAllComments)}>
          {showAllComments ? 'Show less comments' : 'Show more comments'}
        </div>
      )}

      <div className="">
        <div className="bg-gray-200 rounded-card flex flex-col w-full" ref={textareaRef}>
          <textarea
            className="w-full py-2 px-3  rounded-card bg-gray-200 resize-none focus:outline-none"
            placeholder="Add a comment..."
            value={newComment}
            onChange={handleTextareaInput}
            onFocus={() => setIsAddingComment(true)}
       
          />
          {isAddingComment && (
            // <div className="flex justify-end p-2">
              <button className="text-blue-600 hover:bg-white rounded-full pe-3 pb-3 flex justify-end " onClick={handleCreateComment}>
                <FaPaperPlane />
              </button>
            // </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Comments;
