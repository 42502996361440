/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { IoSearch } from "react-icons/io5";
import { requestForToken } from '../firebase';
import { fetchAllGroups, fetchAllUserInfo, updateUser, updateGroup, fetchUserByEmail } from '../Api/apiService';
import { Dialog } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';

const AllGroups = ({ currentUserEmail }) => {
  const [allGroups, setAllGroups] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const allUserInfo = await fetchAllUserInfo();
        const currentUser = allUserInfo.find(user => user.userEmail === currentUserEmail);

        if (currentUser) {
          const groupsData = await fetchAllGroups();
          setAllGroups(groupsData.filter(group => !group.memberEmails.includes(currentUserEmail)));
        } else {
          setError('User document not found');
        }
      } catch (error) {
        console.error('Error fetching groups:', error);
        setError('Error fetching groups. Please try again later.');
      }
    };
    fetchGroups();
  }, [currentUserEmail]);

  const handleJoinGroup = async (groupId) => {
    try {
      if (typeof Notification === 'undefined') {
        setIsModalOpen(true);
        return;
      }

      if (Notification.permission !== 'granted') {
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
          console.warn('User denied notification permission');
          return;
        }
      }

      const currentToken = await requestForToken();
      const currentUser = await fetchUserByEmail(currentUserEmail);

      if (currentUser) {
        if (currentToken) {
          await updateUser(currentUser.id, { ...currentUser, fcmtoken: currentToken });
        }

        const groupData = await fetchAllGroups().then(groupsData => groupsData.find(group => group.groupId === groupId));
        if (groupData) {
          const updatedGroup = { ...groupData, memberEmails: [...new Set([...groupData.memberEmails, currentUserEmail])] };
          await updateGroup(groupId, updatedGroup);
          const updatedUser = { ...currentUser, joinedGroupIDs: [...new Set([...currentUser.joinedGroupIDs, groupId])] };
          await updateUser(currentUser.id, updatedUser);

          localStorage.setItem(`userData-${currentUserEmail}`, JSON.stringify(updatedUser));
          setAllGroups(prevGroups => prevGroups.filter(group => group.groupId !== groupId));

          const updatedGroupsData = await fetchAllGroups();
          const joinedGroups = updatedGroupsData.filter(group => group.memberEmails.includes(currentUserEmail) && !group.adminEmails.includes(currentUserEmail));
          localStorage.setItem(`linkedGroupData-${currentUserEmail}`, JSON.stringify(joinedGroups));

          $('#exampleModal').modal('show');
          setTimeout(() => $('#exampleModal').modal('hide'), 5000);
        } else {
          setError('Group not found');
        }
      } else {
        setError('User not found');
      }
    } catch (error) {
      console.error('Error joining group:', error);
      setError('An error occurred while joining the group. Please try again later.');
    }
  };

  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  const filteredGroups = allGroups.filter(group => {
    const query = searchQuery.toLowerCase();
    return (
      (group.groupName && group.groupName.toLowerCase().includes(query)) ||
      (group.ownerName && group.ownerName.toLowerCase().includes(query)) ||
      (group.description && group.description.toLowerCase().includes(query)) ||
      (group.ownerEmail && group.ownerEmail.toLowerCase().includes(query)) ||
      (group.groupId && group.groupId.toString().toLowerCase().includes(query)) ||
      (group.zipcode && group.zipcode.toLowerCase().includes(query))
    );
  });

  return (
    <div>
      <div className='pt-3'>
        <div className="flex text-white mb-5 m-3 bg-white rounded ring-1 ring-gray-900/5">
          <div className='ms-3 text-slate-400 h2 mt-2 '>
            <IoSearch />
          </div>
          <input
            type="text"
            className="mt-1 text-slate-600 placeholder:italic placeholder:text-slate-400 form-control bg-white rounded border border-0 focus:outline-none focus:shadow-none"
            id="search"
            placeholder='Search group name, owner, group id, or zip'
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      {error && <p className="text-danger">{error}</p>}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {filteredGroups.map(group => (
          <div className="mx-2 mb-2 ring-1 ring-gray-900/5 p-3 rounded-card bg-white" key={group.groupId}>
            <div className="d-flex">
              <div>
                <img 
                  src="./images/Group Created.png"
                  alt=""
                  style={{ width: '50px', height: '50px', objectFit: 'contain', borderRadius: '20px' }}
                />
              </div>
              <div className="card-body ps-3">
                <p className="text-lg fw-semibold card-title pt-2">{group.groupName}</p>
                <p className="text-gray-600 text-sm "><span className="fw-bold card-text"> Leader: </span> {group.ownerName}</p>
              </div>
            </div>
            <div key={group.groupId} className="">
              <div className="mt-1 mb-2 justify-content-between d-flex ">
                <p className="mt-1 flex text-sm leading-5 text-gray-600 ">
                  <span className="fw-bold   me-1">About: </span> 
                  {group.description}
                </p>
              </div>
              <div className="collapse" id={`collapseExample${group.groupId}`}>
                <div className="card card-body">
                  <p className="text-sm"><span className="fw-bold text-sm">Leader email: </span> {group.ownerEmail}</p>
                  Description: {group.description}
                </div>
              </div>
              <button
                className=" btn btn-success ms-3 rounded-pill flex ms-auto"
                onClick={() => handleJoinGroup(group.groupId)}
              >
                Join
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="modal shadow-lg" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content bg-success shadow-lg">
            <div className="modal-header ">
              <h5 className="modal-title text-white" id="exampleModalLabel">Success!</h5>
            </div>
            <div className="modal-body">
              <p className='text-white'>You have successfully joined the group.</p>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
  <Dialog className="relative z-10" open={isModalOpen} onClose={() => setIsModalOpen(false)}>
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-start sm:p-0">
        <Dialog.Panel
          className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
        >
          <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                Install App for Full Functionality
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  In order to receive updates and status requests from your group leader, please install this app on your home screen.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setIsModalOpen(false)}
            >
              Continue
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </div>
  </Dialog>
)}

    </div>
  );
};

export default AllGroups;
