import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AppendGroup from '../components/appendGroup';
import HomeFAB from '../components/homepageFAB';
import AllGroups from '../components/allGroups';
import MemberGroups from '../components/Group Comps/GroupLinked';
import OffcanvasMenu from '../components/OffcanvasMenu';
import ProfileButton from '../components/ProfileButton';
import UserInfo from '../components/userInfo';
import { fetchAllUserInfo, fetchAllGroups, updateUser } from '../Api/apiService';
import { RiCommunityFill } from "react-icons/ri";
import { FaPeopleRoof } from "react-icons/fa6";
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import NotificationBadge from '../components/Notification Comps/NotificationBadge.js'
import { CgAddR } from "react-icons/cg";
import { Carousel } from 'react-bootstrap'; // Add this import

const HomePage = () => {
  const [activeTab, setActiveTab] = useState('myGroups');
  const [activeSubTab, setActiveSubTab] = useState('createdCircles');
  const currentUserEmail = localStorage.getItem('currentUserEmail');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [joinedGroupId, setJoinedGroupId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [groupData, setGroupData] = useState([]);
  const [linkedGroupData, setLinkedGroupData] = useState([]);
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [archivedNotifications, setArchivedNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const joingroupId = localStorage.getItem('joingroupId');
        if (joingroupId) {
          const userInfo = await fetchAllUserInfo();
          const currentUser = userInfo.find(user => user.userEmail === currentUserEmail);
          if (currentUser) {
            const updatedUser = { ...currentUser, joinedGroupIDs: [...currentUser.joinedGroupIDs, joingroupId] };
            await updateUser(currentUser.id, updatedUser);
            setJoinedGroupId(joingroupId);

            // Add a new notification for joining a group
            const newNotification = {
              id: `join-${joingroupId}`,
              type: 'joinedGroup',
              groupName: updatedUser.groupName,
              timestamp: new Date().toISOString(),
            };
            addNotification(newNotification);
          }
        }

        if (currentUserEmail) {
          const userInfo = await fetchAllUserInfo();
          const currentUser = userInfo.find(user => user.userEmail === currentUserEmail);

          setUserData(userInfo);
          localStorage.setItem(`userData-${currentUserEmail}`, JSON.stringify(currentUser));

          const groupsData = await fetchAllGroups();
          const managedGroups = groupsData.filter(group => group.adminEmails.includes(currentUserEmail));
          const joinedGroups = groupsData.filter(group => group.memberEmails.includes(currentUserEmail) && !group.adminEmails.includes(currentUserEmail));
          setGroupData(managedGroups);
          setLinkedGroupData(joinedGroups);
          localStorage.setItem(`groupData-${currentUserEmail}`, JSON.stringify(managedGroups));
          localStorage.setItem(`linkedGroupData-${currentUserEmail}`, JSON.stringify(joinedGroups));

          const userProfileData = JSON.parse(localStorage.getItem(`userData-${currentUserEmail}`));
          setUserProfile(userProfileData);

          // Load archived notifications from localStorage
          const savedArchivedNotifications = JSON.parse(localStorage.getItem('archivedNotifications')) || [];
          setArchivedNotifications(savedArchivedNotifications);

          // Load notifications from localStorage
          const savedNotifications = JSON.parse(localStorage.getItem('notifications')) || [];
          setNotifications(savedNotifications);

          // Set notification count
          setNotificationCount(savedNotifications.length);
        }

        setDataLoaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [currentUserEmail]);

  useEffect(() => {
    // Save notifications to localStorage whenever they change
    localStorage.setItem('notifications', JSON.stringify(notifications));
    localStorage.setItem('archivedNotifications', JSON.stringify(archivedNotifications));

    // Update notification count
    setNotificationCount(notifications.length);
  }, [notifications, archivedNotifications]);

  const addNotification = (notification) => {
    setNotifications(prevNotifications => {
      const updatedNotifications = [...prevNotifications, notification];
      localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
      return updatedNotifications;
    });
  };

  const removeNotification = (id) => {
    setNotifications(prevNotifications => {
      const updatedNotifications = prevNotifications.filter(notification => notification.id !== id);
      localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
      return updatedNotifications;
    });
  };

  const archiveNotification = (id) => {
    const notification = notifications.find(notification => notification.id === id);
    setArchivedNotifications(prevArchivedNotifications => {
      const updatedArchivedNotifications = [...prevArchivedNotifications, notification];
      localStorage.setItem('archivedNotifications', JSON.stringify(updatedArchivedNotifications));
      return updatedArchivedNotifications;
    });
    removeNotification(id);
  };

  const toggleOffcanvas = () => {
    setOffcanvasOpen(!offcanvasOpen);
  };

  const handleSignOut = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (!dataLoaded) {
    return (
      <div className="d-flex justify-content-center align-items-center bg-white" style={{ minHeight: '100vh' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <main className='mt-5 h-screen'>
        <div className='border-b-2 mobilehide'>
          <ul className="pb-4 container nav nav-pills text-2xl grid grid-cols-3 grid-rows-1">
            <li className="nav-item col-span-2 justify-self-between ">
              <img src='/images/Status Now Icon – dark.png' alt='Status Now Icon – dark' width='300' height='400' />
            </li>
            
            <li className="justify-self-end d-flex">
            
           
          <div>

          {/* <Link to="/embedded-site">
                <img className='mt-1 mx-4 fill-blue-500' src="/images/Sitprep icon.svg" alt="Description of the image" width="60" height="60" />
              </Link> */}
          </div>
            <div>
              <ProfileButton userProfile={userProfile} toggleOffcanvas={toggleOffcanvas} />
              <OffcanvasMenu
                userProfile={userProfile}
                offcanvasOpen={offcanvasOpen}
                toggleOffcanvas={toggleOffcanvas}
                handleSignOut={handleSignOut}
              />
              </div>    
            </li>
          </ul>
        </div>

        <section className="rounded mt-3 container padding-main">
        <div className="flex justify-center mobileshow">
          <img src='/images/Status Now Icon – dark.png' alt='Status Now Icon – dark' width='250' height='300' />
        </div>


        <UserInfo currentUserEmail={currentUserEmail} />
          <div className='bg-nav shadow-sm rounded-xl'>
            
            <main className='p-5 rounded'>
            <Carousel>
                <Carousel.Item interval={100000}>
                  <img
                    className="d-block rounded"
                    src="/images/Bsuiness.png"
                    alt="First slide"
                    width="100" height="100"
                  />
                  <Carousel.Caption>
                    {/* <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={500}>
                  <img
                    className="d-block"
                    src="/images/Bsuiness.png"
                    alt="Second slide"
                    width="100" height="100"
                  />
                  <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block"
                    src="/images/Bsuiness.png"
                    alt="Third slide"
                    width="100" height="100"
                  />
                  <Carousel.Caption>
                    <h3>Third slide label</h3>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>

              
              </main>

              
          </div>

             

          {activeTab === 'myGroups' && (
            <section className='pt-3'>
      <ul className="nav nav-pills justify-content-center pt-4 rounded-pill  ">
            <li className="nav-item">
                <button
                  className={`rounded-pill mx-2 py-2 px-3 text-2xl  ${
                    activeSubTab === 'createdCircles'
                      ? 'bg-black text-white fw-bold'
                      : 'text-black'
                  }`}
                  onClick={() => setActiveSubTab('createdCircles')}
                >
                  <FaPeopleRoof />
                </button>
            </li>
            <li className="nav-item">
              <button
                className={`rounded-pill mx-2 py-2 px-3 ${
                  activeSubTab === 'joinedCircles'
                    ? 'bg-black text-white fw-bold'
                      : 'text-black'
                }`}
                onClick={() => setActiveSubTab('joinedCircles')}
              >
                Joined
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`rounded-pill mx-2 py-2 px-3 ${
                  activeSubTab === 'community'
                    ? 'bg-black text-white fw-bold'
                      : 'text-black'
                }`}
                onClick={() => setActiveSubTab('community')}
              >
                Discover
              </button>
            </li>

        </ul>

              {activeSubTab === 'createdCircles' && (
                <section className='pt-3 '>
                  <div className='bg-white ring-1 ring-gray-900/5 rounded-xl pb-5'>
                    <div className='d-flex justify-content-between me-3 mt-4'>
                      <p className='text-dark pb-3 fw-semibold text-lg mx-4 mt-4'>My Circles</p>
                      <Link className='mobileshow text-success text-4xl my-3' to={'/create-group'}>
                        <CgAddR />
                      </Link>
                    </div>
                    <ul className="divide-y divide-gray-100 overflow-hidden   ">
                      {userData && <AppendGroup currentUserEmail={currentUserEmail} />}
                    </ul>
                  </div>
                </section>
              )}

              {activeSubTab === 'joinedCircles' && (
                <section className='pt-3 mt-4'>
                  <div className='bg-white ring-1 ring-gray-900/5 rounded-xl pb-5'>
                    <p className='text-dark pb-3 fw-semibold text-lg mx-4 pt-4'>Associated Circles</p>
                    <ul className="divide-y divide-gray-100 overflow-hidden ">
                      {userData && <MemberGroups currentUserEmail={currentUserEmail} handleHomePageButton={() => setActiveTab('community')} />}
                    </ul>
                  </div>
                </section>
              )}

              {activeSubTab === 'community' && (
                <section className='pt-3 mt-4'>
                  <div className='mt-3 bg-white ring-1 ring-gray-900/5 rounded-xl pb-5'>
                  <p className='text-dark pb-3 fw-semibold text-lg mx-4 pt-4'>Join Other Groups</p>
                    <AllGroups currentUserEmail={currentUserEmail} />
                  </div>
                </section>
              )}
            </section>
          )}
        </section>
        <div className='mobilehide'>
          <HomeFAB currentUserEmail={currentUserEmail} />
        </div>
      </main>
      <footer className='mobileshow border-top fixed-bottom navbar bg-white pb-4'>
        <ul className="mb-1 grid grid-cols-6 grid-rows-1">
          <li className="mx-4 col-span-5 flex justify-evenly">
          {/* <Link to="/sitprep">
            <img className='mt-3 fill-blue-500' src="/images/Sitprep icon.svg" alt="Description of the image" width="28" height="28" />

              </Link> */}

            <NotificationBadge count={notificationCount} currentUserEmail={currentUserEmail} />
          </li>
          <li className="justify-self-end">
            <ProfileButton userProfile={userProfile} toggleOffcanvas={toggleOffcanvas} width="40px" height="40px" fontSize='20px' fontWeight="bold" />
            <OffcanvasMenu
              userProfile={userProfile}
              offcanvasOpen={offcanvasOpen}
              toggleOffcanvas={toggleOffcanvas}
              handleSignOut={handleSignOut}
            />
          </li>
        </ul>
      </footer>
    </>
  );
};

export default HomePage;
