// AppContext.js
import React, { createContext, useReducer } from 'react';

const initialState = {
  // Define your initial global state properties here
  // For example: user, settings, etc.
};

const AppContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    // Add cases for different actions
    // For example: case 'SET_USER', case 'UPDATE_SETTINGS', etc.

    default:
      return state;
  }
};

const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
