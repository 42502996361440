import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { updateUser } from '../Api/apiService';
import { uploadFileToStorage, getDownloadURL, ref, storage, deleteObject } from '../firebase'; 
import { UserCircleIcon } from '@heroicons/react/24/solid';

export default function ProfilePage() {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    userStatus: '',
    address: '',
    phone: '',
    dateOfBirth: '',
    profileImageURL: '',
    statusColor: '',
    title:'',
  });
  const [fullname, setFullname] = useState('');
  const [prevImageURL, setPrevImageURL] = useState('');
  const [localFormData, setLocalFormData] = useState({});
  const navigate = useNavigate();

  const currentUserEmail = localStorage.getItem('currentUserEmail');
  const userData = JSON.parse(localStorage.getItem(`userData-${currentUserEmail}`));
  const userId = userData?.id;

  const handleGoBack = () => {
    navigate('/home');
  };

  useEffect(() => {
    if (userData) {
      setFormData(userData);
      setFullname(`${userData.userFirstName} ${userData.userLastName}`);
      if (userData.profileImageURL.startsWith("https://firebasestorage.googleapis.com/")) {
        setPrevImageURL(userData.profileImageURL);
      }
      setLocalFormData(userData);
    }
  }, []); 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalFormData({
      ...localFormData,
      [name]: value,
    });
  };

  const handleSaveProfile = async () => {
    const updatedUser = {
      id: userId,
      userFirstName: localFormData.userFirstName,
      userLastName: localFormData.userLastName,
      userEmail: localFormData.userEmail,
      title: localFormData.title || '',
      phone: localFormData.phone || '',
      address: localFormData.address || '',
      userStatus: localFormData.userStatus,
      statusColor: localFormData.statusColor,
      profileImageURL: localFormData.profileImageURL,
      managedGroupIDs: localFormData.managedGroupIDs || [],
      joinedGroupIDs: localFormData.joinedGroupIDs || [],
      groupAlert: localFormData.groupAlert || 'Default Alert',
      subscription: localFormData.subscription || 'Default Subscription',
      subscriptionPackage: localFormData.subscriptionPackage || 'Default Package',
      dateSubscribed: localFormData.dateSubscribed || new Date().toISOString(),
      fcmtokens: localFormData.fcmtokens || []
    };

    try {
      console.log('Updated User:', updatedUser);
      localStorage.setItem(`userData-${currentUserEmail}`, JSON.stringify(updatedUser));
      const response = await updateUser(userId, updatedUser);
      console.log('API Response:', response);
      setFormData(updatedUser);
      navigate('/home');
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleStatusChange = (newStatus) => {
    let newStatusColor = '';
    switch (newStatus) {
      case 'SAFE':
        newStatusColor = '#1BBC9B';
        break;
      case 'INJURED':
        newStatusColor = '#F25A7C';
        break;
      case 'HELP':
        newStatusColor = '#FFC107';
        break;
      default:
        newStatusColor = '#1BBC9B';
    }

    const updatedFormData = {
      ...localFormData,
      userStatus: newStatus,
      statusColor: newStatusColor,
    };

    setLocalFormData(updatedFormData);
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      if (prevImageURL && prevImageURL.startsWith("https://firebasestorage.googleapis.com/")) {
        const prevRef = ref(storage, prevImageURL);
        await deleteObject(prevRef).catch((deleteError) => {
          console.error('Error deleting previous image:', deleteError);
        });
      }

      const snapshot = await uploadFileToStorage(file, `${currentUserEmail}/${file.name}`);
      const downloadURL = await getDownloadURL(snapshot.ref);
      const updatedFormData = {
        ...localFormData,
        profileImageURL: downloadURL,
      };
      setLocalFormData(updatedFormData);
      setPrevImageURL(downloadURL);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSaveProfile();
  };

  const handleSaveButtonClick = () => {
    document.getElementById('profileForm').requestSubmit();
  };

  return (
    <div className="space-y-10 divide-y divide-gray-900/10 px-4 py-8">
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>
        </div>

        <form id="profileForm" className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2" onSubmit={handleFormSubmit}>
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label htmlFor="profileImage" className="block text-sm font-medium leading-6 text-gray-900">Profile Image</label>
                <div className="mt-2 flex items-center gap-x-3">
                  {localFormData.profileImageURL ? (
                    <img src={localFormData.profileImageURL} alt="Profile" className="h-12 w-12 rounded-full" />
                  ) : (
                    <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true" />
                  )}
                  <label htmlFor="profileImage" className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 cursor-pointer">
                    Change Image
                  </label>
                  <input type="file" id="profileImage" onChange={handleImageUpload} className="sr-only" />
                </div>
                <p className="mt-2 text-sm text-gray-500">JPG, GIF, or PNG. 1MB max.</p>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">First Name</label>
                <input
                  type="text"
                  name="userFirstName"
                  id="firstName"
                  value={localFormData.userFirstName}
                  onChange={handleInputChange}
                  className="ps-3 mt-2 block w-full rounded-md border border-gray-300 py-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required
                />
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">Last Name</label>
                <input
                  type="text"
                  name="userLastName"
                  id="lastName"
                  value={localFormData.userLastName}
                  onChange={handleInputChange}
                  className="ps-3 mt-2 block w-full rounded-md border border-gray-300 py-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required
                />
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">Title</label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  value={localFormData.title || ''}
                  onChange={handleInputChange}
                  className="ps-3 mt-2 block w-full rounded-md border border-gray-300 py-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email Address</label>
                <input
                  type="email"
                  name="userEmail"
                  id="email"
                  value={localFormData.userEmail}
                  onChange={handleInputChange}
                  className="ps-3 mt-2 block w-full rounded-md border border-gray-300 py-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required
                  disabled
                />
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">Phone Number</label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  value={localFormData.phone || ''}
                  onChange={handleInputChange}
                  className="ps-3 mt-2 block w-full rounded-md border border-gray-300 py-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">Address</label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  value={localFormData.address || ''}
                  onChange={handleInputChange}
                  className="ps-3 mt-2 block w-full rounded-md border border-gray-300 py-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={handleGoBack}>Cancel</button>
          <button type="button" onClick={handleSaveButtonClick} className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Save
          </button>
        </div>
        </form>
      </div>
    </div>
  );
}
