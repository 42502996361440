import { register } from 'register-service-worker';

export function registerServiceWorker() {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // Register the main service worker
    register(`${process.env.PUBLIC_URL}/service-worker.js`, {
      ready() {
        console.log('App is being served from cache by a service worker.');
      },
      cached() {
        console.log('Content has been cached for offline use.');
      },
      updatefound() {
        console.log('New content is available; please refresh.');
      },
      offline() {
        console.log('No internet connection found. App is running in offline mode.');
      },
      error(error) {
        console.error('Error during service worker registration:', error);
      },
    });

    // Register the Firebase messaging service worker
    register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`, {
      ready() {
        console.log('Firebase Messaging Service Worker is registered.');
      },
      cached() {
        console.log('Firebase Messaging Service Worker content has been cached.');
      },
      updatefound() {
        console.log('New Firebase Messaging Service Worker content is available; please refresh.');
      },
      offline() {
        console.log('No internet connection found. Firebase Messaging Service Worker is running in offline mode.');
      },
      error(error) {
        console.error('Error during Firebase Messaging Service Worker registration:', error);
      },
    });
  }
}
