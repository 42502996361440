import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import SignInWithGoogle from '../signInWithGoogle';

const Register = () => {
  const { createUserMeth } = useAuth();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    setError('');

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const userData = {
        userFirstName: formData.firstName,
        userLastName: formData.lastName,
        userEmail: formData.email,
        password: formData.password,
        userStatus: 'No Response',
        statusColor: 'grey',
        profileImageURL: '',
        fcmtoken: '',
        managedGroupIDs: [],
        joinedGroupIDs: [],
        groupAlert: 'All',
        subscription: 'Basic',
        subscriptionPackage: 'Monthly',
        dateSubscribed: new Date().toISOString(),
        phone:'',
        title:'',
        address:''
      };

      await createUserMeth(userData.userEmail, userData.password, userData);

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
      });
    } catch (error) {
      if (error.message === 'Email already in use') {
        setError('This email is already in use.');
      } else {
        setError('Failed to create account. Please try again later.');
      }
      console.error('Error creating account:', error);
    }
  };

  return (
    <div className='bg-white container pt-5' style={{ height: '140vh' }}>
      <p className='h1 mt-3'>Free Sign Up</p>
      {error && <p className="text-danger">{error}</p>}
      <form onSubmit={handleCreateAccount}>
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            className="mb-4 form-control bg-light"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            className="mb-4 form-control bg-light"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            className="mb-4 form-control bg-light"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group mb-4">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="form-control bg-light"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
          <div id="passwordHelpBlock" className="form-text">
            Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji.
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            className="form-control bg-light"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            required
          />
        </div>
        <button className="w-100 mt-4 btn btn-primary" type="submit">
          Create Status Now Account 
        </button>
      </form>

      <div className="mx-5 py-4 horizontal-lines-container">
        <hr className="pt-3 horizontal-line"></hr>
        <p className='px-3'>OR</p>
        <hr className="pt-3 horizontal-line"></hr>
      </div>

      <div className='mb-5'>
        <SignInWithGoogle />
      </div>
    </div>
  );
};

export default Register;
