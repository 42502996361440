import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchGroupById, updateGroup, deleteGroup } from '../../Api/apiService';

const ManageGroup = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();

  const [groupData, setGroupData] = useState({
    groupName: '',
    description: '',
    groupType: '',
    privacy: 'Public',
    zipCode: '',
    groupCode: '', // Add state for groupCode
  });

  useEffect(() => {
    const loadGroupData = async () => {
      try {
        const group = await fetchGroupById(groupId);
        setGroupData(group);
      } catch (error) {
        console.error('Error fetching group data:', error);
      }
    };

    loadGroupData();
  }, [groupId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGroupData({
      ...groupData,
      [name]: value,
    });
  };

  const handleUpdateGroup = async (e) => {
    e.preventDefault();
    try {
      await updateGroup(groupId, groupData);
      console.log('Group updated successfully!');
      navigate(`/linked/${groupId}`);
    } catch (error) {
      console.error('Error updating group:', error);
    }
  };

  const handleDeleteGroup = async () => {
    if (window.confirm('Are you sure you want to delete this group?')) {
      try {
        await deleteGroup(groupId);
        console.log('Group deleted successfully!');
        navigate('/'); // Redirect to home page after deletion
      } catch (error) {
        console.error('Error deleting group:', error);
      }
    }
  };

  const handleClose = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div className="container my-5 py-5 bg-white">
      <div className="row">
        <section className='col-md-4'>
          <p className='h5 fw-bold mb-3'>Group Information</p>
          <p className='mb-5'>Update your group information.</p>
        </section>

        <section className='col-md-8'>
          <form onSubmit={handleUpdateGroup}>
            <div className="mb-3">
              <label htmlFor="groupName" className="form-label font-bold">Group Name</label>
              <input
                type="text"
                className="form-control"
                id="groupName"
                name="groupName"
                value={groupData.groupName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label font-bold">Description</label>
              <textarea
                className="form-control"
                id="description"
                name="description"
                value={groupData.description}
                onChange={handleInputChange}
                rows="3"
              ></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor="groupType" className="form-label font-bold">Group Type</label>
              <input
                type="text"
                className="form-control"
                id="groupType"
                name="groupType"
                value={groupData.groupType}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="privacy" className="form-label font-bold">Privacy</label>
              <select
                className="form-select"
                id="privacy"
                name="privacy"
                value={groupData.privacy}
                onChange={handleInputChange}
                required
              >
                <option value="Public">Public</option>
                <option value="Private">Private</option>
              </select>
            </div>
            {groupData.privacy === 'Public' && (
              <div className="mb-3">
                <label htmlFor="zipCode" className="form-label font-bold">Zip Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="zipCode"
                  name="zipCode"
                  value={groupData.zipCode}
                  onChange={handleInputChange}
                  required
                />
              </div>
            )}
            <div className="mb-3">
              <label htmlFor="groupCode" className="form-label font-bold">Group Code</label>
              <input
                type="text"
                className="form-control"
                id="groupCode"
                name="groupCode"
                value={groupData.groupCode}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mt-5 d-grid gap-4 d-md-flex justify-content-md-end">
              <button type="submit" className="btn btn-primary me-md-2">Save Changes</button>
              <button type="button" className="btn btn-danger me-md-2" onClick={handleDeleteGroup}>Delete Group</button>
              <button type="button" className="btn btn-dark" onClick={handleClose}>Close</button>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default ManageGroup;
