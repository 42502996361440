import React, { useState, useEffect } from 'react';
import { fetchAllUserInfo, fetchAllGroups, fetchGroupById, updateGroup, updateUser } from '../Api/apiService';
import SubGroup from './SubGroups.js';
import IndividualUser from './IndividualUser.js';

const CircularProgress = ({ progress }) => {
  const radius = 40;
  const circumference = 2 * Math.PI * radius;
  const dashoffset = circumference - (progress / 100) * circumference;

  return (
    <div className=' d-flex my-3'>
      <p className="h5 fw-bolder ">
        Individuals Checked In | {progress}%
      </p>
    </div>
  );
};

const GroupPageStatus = ({ groupId, stopFetchingData, setShowRefreshButton, setShowSwipeButton, userListData, setUserListData }) => {
  const [filter, setFilter] = useState('ALL');
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        const groupsData = await fetchAllGroups();
        const group = groupsData.find(g => g.groupId === groupId);
        setSelectedGroup(group);
      } catch (error) {
        console.error('Error fetching group data:', error);
      }
    };

    fetchGroupData();
  }, [groupId]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userEmails = selectedGroup.memberEmails;
        const allUserInfo = await fetchAllUserInfo();
        const userData = allUserInfo.filter(user => userEmails.includes(user.userEmail));
        setUserListData(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (selectedGroup) {
      fetchUserData();
    }
  }, [selectedGroup]);

  const handleFilterClick = (status) => {
    setFilter(status);
  };

  if (!selectedGroup) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <GroupPageStat 
        userListData={userListData} 
        filter={filter} 
        groupId={groupId} 
        stopFetchingData={stopFetchingData} 
        setShowRefreshButton={setShowRefreshButton} 
        setShowSwipeButton={setShowSwipeButton} 
        setUserListData={setUserListData} // Pass the state setter
      />
    </div>
  );
};

const GroupPageStat = ({ userListData, filter, groupId, stopFetchingData, setShowRefreshButton, setShowSwipeButton, setUserListData }) => {
  const handleEndRequest = async (groupId) => {
    try {
      // Fetch the current group data
      const groupData = await fetchGroupById(groupId);

      // Update only the alert status
      const updatedGroupData = { ...groupData, alert: 'Inactive' };

      // Update the group in the backend
      await updateGroup(groupId, updatedGroupData);

      // Update all members' status to 'Safe' and statusColor to 'Green'
      const userEmails = groupData.memberEmails;
      const allUserInfo = await fetchAllUserInfo();
      const userData = allUserInfo.filter(user => userEmails.includes(user.userEmail));

      for (const user of userData) {
        await updateUser(user.id, { ...user, userStatus: 'SAFE', statusColor: 'Green' });
      }

      // Update local storage
      const currentUser = localStorage.getItem('currentUserEmail');
      let groupDataLocal = JSON.parse(localStorage.getItem(`groupData-${currentUser}`));

      let targetGroupIndex = groupDataLocal.findIndex(group => group.groupId.toString() === groupId);

      if (targetGroupIndex !== -1) {
        groupDataLocal[targetGroupIndex].alert = 'Inactive';
      }

      localStorage.setItem(`groupData-${currentUser}`, JSON.stringify(groupDataLocal));

      console.log(`Alert status updated for group ${groupId}. New status: Inactive`);

      // Fetch updated user data and update state
      const updatedAllUserInfo = await fetchAllUserInfo();
      const updatedUserData = updatedAllUserInfo.filter(user => userEmails.includes(user.userEmail));
      setUserListData(updatedUserData);

      // Stop fetching data, hide refresh button, show SwipeButton, hide getStatusInfo element
      stopFetchingData();
      setShowRefreshButton(false); // Hide Refresh button
      setShowSwipeButton(true); // Show SwipeButton
    } catch (error) {
      console.error('Error updating group alert status:', error);
    }
  };

  const membersWithStatus = userListData.filter(user => user.userStatus !== 'NO RESPONSE').length;
  const totalMembers = userListData.length;
  const percentage = Math.round((membersWithStatus / totalMembers) * 100);

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-center me-2 mb-3">
        <button
          className='btn rounded-pill btn-dark'
          onClick={() => handleEndRequest(groupId)}
        >
          End Request
        </button>
      </div>
      <div className="">
        <div className="mr-2">
          <i className="bi bi-emoji-laughing text-muted" style={{ fontSize: '25px' }}></i>
        </div>
        <div className="d-flex justify-content-center">
          <div>
            <CircularProgress progress={percentage} />
          </div>
        </div>
      </div>
    </div>
  );
};

const GroupPageStatusCardWidget = ({ groupId, stopFetchingData, setShowRefreshButton, setShowSwipeButton, userListData, setUserListData, showGetStatusInfo }) => {
  const [filter, setFilter] = useState('ALL');
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        const groupsData = await fetchAllGroups();
        const group = groupsData.find(group => group.groupId.toString() === groupId);
        setSelectedGroup(group);
      } catch (error) {
        console.error('Error fetching group data:', error);
      }
    };

    fetchGroupData();
  }, [groupId]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userEmails = selectedGroup.memberEmails;
        const allUserInfo = await fetchAllUserInfo();
        const userData = allUserInfo.filter(user => userEmails.includes(user.userEmail));
        setUserListData(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (selectedGroup) {
      fetchUserData();
    }
  }, [selectedGroup]);

  const handleFilterClick = (status) => {
    setFilter(status);
  };

  if (!selectedGroup) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div id='getstatusInfo' className={`getstatusInfo ${showGetStatusInfo ? '' : 'd-none'}`}>
      
        <div className=''>
          <GroupPageStat 
            userListData={userListData} 
            filter={filter} 
            groupId={groupId} 
            stopFetchingData={stopFetchingData} 
            setShowRefreshButton={setShowRefreshButton} 
            setShowSwipeButton={setShowSwipeButton} 
            setUserListData={setUserListData} // Pass the state setter
          />
        </div>
        <div className="col-12 mb-3 snap-x">
          <div className="d-flex flex-nowrap overflow-x-auto scrollbar-hide">
            <div className="d-flex align-items-center mx-3">
              <button
                className={`m-1 shadow-sm d-flex btn btn-sm rounded-pill custom-bg-all ${filter === 'ALL' ? 'Active' : ''}`}
                onClick={() => handleFilterClick('ALL')}
              >
                ALL <span className="ms-2">{userListData.length}</span>
              </button>
            </div>
            <div className="d-flex align-items-center mx-3">
              <button
                className={`m-1 shadow-sm fw-bolder d-flex btn btn-sm rounded-pill custom-bg-red ${filter === 'INJURED' ? 'Active' : ''}`}
                onClick={() => handleFilterClick('INJURED')}
              >
                INJURED <span className="ms-2">{userListData.filter(user => user.userStatus === 'INJURED').length}</span>
              </button>
            </div>
            <div className="d-flex align-items-center mx-3">
              <button
                className={`m-1 shadow-sm fw-bolder d-flex btn btn-sm rounded-pill custom-bg-yellow ${filter === 'HELP' ? 'Active' : ''}`}
                onClick={() => handleFilterClick('HELP')}
              >
                HELP <span className="ms-2">{userListData.filter(user => user.userStatus === 'HELP').length}</span>
              </button>
            </div>
            <div className="d-flex align-items-center mx-3">
              <button
                className={`m-1 shadow-sm fw-bolder text-nowrap btn btn-sm rounded-pill custom-bg-purple ${filter === 'NO RESPONSE' ? 'Active' : ''}`}
                onClick={() => handleFilterClick('NO RESPONSE')}
              >
                NO RESPONSE <span className="ms-2">{userListData.filter(user => user.userStatus === 'NO RESPONSE').length}</span>
              </button>
            </div>
            <div className="d-flex align-items-center mx-3">
              <button
                className={`m-1 shadow-sm fw-bolder d-flex btn btn-sm rounded-pill custom-bg-green ${filter === 'SAFE' ? 'Active' : ''}`}
                onClick={() => handleFilterClick('SAFE')}
              >
                SAFE <span className="ms-2">{userListData.filter(user => user.userStatus === 'SAFE').length}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <section className='scrollable-home border-top '>
        <p className='text-secondary font-bold text-xl py-4 ps-3'>MEMBERS</p>
        <div className='pb-5'>
          {userListData.map((user, index) => {
            if (filter === 'ALL' || user.userStatus === filter) {
              return <IndividualUser key={index} userList={[user.userEmail]} />;
            }
            return null;
          })}
          {selectedGroup.subGroupIDs.map((subgroupId, index) => {
            return <SubGroup key={index} groupId={subgroupId} />;
          })}
        </div>
      </section>
    </div>
  );
};

export default GroupPageStatusCardWidget;
