/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import '../App.css';

import IconButton from '@mui/material/IconButton';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
// import ArrowBackwardIcon from '@mui/icons-material/ArrowBackward';







const db = getFirestore();

const NavbarMain = () => {
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [userFirstName, setUserFirstName] = useState('');

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (currentUser) {
        try {
          const userRef = doc(db, 'users', currentUser.email);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            setUserProfile(userDoc.data());
            setUserFirstName(userDoc.data().userFirstName);
          }
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      }
    };

    fetchUserProfile();
  }, [currentUser]);

  const toggleOffcanvas = () => {
    setOffcanvasOpen(!offcanvasOpen);
  };

  const handleSignOut = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <>
  
    <nav className="pt-5 navbar fixed-top bg-white">
      <div className="container-fluid ">
        {/* <Link to="/home" className="navbar-brand">
          <img
            src="/images/Group 847.png"
            alt="Status Now"
            width="60"
            height="60"
          />
        </Link> */}
        {currentUser && (
          <>
         <div>
            
            {/* <Link className='btn text-dark fs-3' to="/" variant="primary">
            <IconButton component={Link} to="/" className='text-dark ' aria-label="back to home">
              <NavigateBeforeIcon />
            </IconButton>
              Home</Link> */}
          </div>
            <button
                className="  shadow "
                type="button"
                onClick={toggleOffcanvas}
                style={{
                  backgroundImage: `url(${
                    userProfile && userProfile.profileImageURL
                      ? userProfile.profileImageURL
                      : ''
                  })`,
                  backgroundColor: `${
                    userProfile && userProfile.profileImageURL
                      ? 'transparent'
                      : '#ffffff' // White background for initials if no profile image
                  }`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '50px',
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  color: '#000000', // Black color for initials
                  textTransform: 'uppercase',
                  borderRadius: '100%',
                  border: '2px solid' ,
                  borderColor:'white',
                  
                }}
              >
                {userProfile && userProfile.profileImageURL
                  ? '' // If there's a profile image URL, don't display initials
                  : `${(userProfile && userProfile.userFirstName.charAt(0)) || ''}${
                      (userProfile && userProfile.userLastName.charAt(0)) || ''
                    }` // Otherwise, display the user's initials
              }
              </button>


          </>
        )}

        <div
          className={` element-pop offcanvas offcanvas-end ${offcanvasOpen ? 'show' : ''}`}
          tabIndex="-1"
          id="offcanvasNavbar"
          
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title">
              {currentUser && (
               <img
               src={
                 userProfile && userProfile.profileImageURL
                   ? userProfile.profileImageURL
                   : 'https://via.placeholder.com/40'
               }
               alt="User Avatar"
               className="rounded-circle shadow"
               width="60"
               height="60"
               style={{ border: '4px solid white' }} // Inline style for white border
             />
             
              )}
            </h5>
            <button
              type="button"
              className="btn-close pt-5 mt-5"
              onClick={toggleOffcanvas}
            ></button>
          </div>
          <div className="offcanvas-body">
                      
                        <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                <div class="text-center">
                  <button class="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Back</button>
                </div>
              </div>

              <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                <div class="text-center"> 
                  <ul class="nav flex-column" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Home</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Profile</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Contact</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#disabled-tab-pane" type="button" role="tab" aria-controls="disabled-tab-pane" aria-selected="false" disabled>Disabled</button>
                    </li>
                  </ul>
                </div>
              </div>





            <ul className="nav flex-column">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/profile" className="nav-link">
                  Profile
                </Link>
              </li>
              {/* Add more links/pages based on your schema */}
            </ul>
            <button className="btn btn-link" onClick={handleSignOut}>
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </nav>
    </>
   
  );
};

export default NavbarMain;
