import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

const MemberGroups = ({ handleHomePageButton }) => {
  const currentUser = localStorage.getItem('currentUserEmail'); // Get the current user's email
  const linkedGroups = JSON.parse(localStorage.getItem(`linkedGroupData-${currentUser}`)); // Get the user's data from local storage
  const [error, setError] = useState('');

  useEffect(() => {
    if (!linkedGroups || !currentUser) {
      setError('No linked groups found or user not authenticated.');
    }
  }, [linkedGroups, currentUser]);

  return (
    <>
      {!linkedGroups || !linkedGroups.length ? (
        <div className='bg-join'>
          <div className="text-center p-4">
            <p className='h4'>Join a Circle</p>
            <p>You don't have any groups you've joined. Explore community circles you would like to join.</p>
            <button className="rounded-pill btn btn-success mt-4" onClick={() => handleHomePageButton()}>
              SN Community
            </button>
          </div>
        </div>
      ) : (
        linkedGroups.map((group) => (
          <Link to={`/Linked/${group.groupId}`} key={group.groupId}>
            <main className='border-bottom border-light'>
            <div className="relative flex justify-between gap-x-6 p-3 hover:bg-gray-50 sm:px-6 ">
              <div className="">
                <img
                  className='shadow-sm'
                  src="./images/Group Created.png"
                  alt=""
                  style={{ width: '50px', height: '50px', borderRadius: '15px' }}
                />
              </div>
              <div className="card-body ">
                <h5 className="text-md font-medium leading-6 text-gray-900 ">{group.groupName}</h5>
                <p className="card-text text-sm text-muted"><span className='fw-semibold'>Admin:</span> {group.ownerName}</p>
              </div>
              <ChevronRightIcon className="mt-2 h-7 w-7  text-gray-400" aria-hidden="true" />
             
            </div>
            {/* <div className="flex justify-center me-4 mb-3">
                <Link className="btn btn-light" to={`/Linked/${group.groupId}`}>
                  View
                </Link>
              </div> */}
             
              </main>
          </Link>
        ))
      )}
      {error && <div className="text-danger text-center mt-3">{error}</div>}
    </>
  );
};

export default MemberGroups;
